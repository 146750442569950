














































































































































import {Component, Vue} from 'vue-property-decorator';
import {PublicREREmployeeDTO} from "@/dto/request/rer/PublicREREmployeeDTO";
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import {ResidencyStatus} from "@/constants/PersonConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {REREmployeeStatus} from "@/constants/request/RERConstants";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";

const AppModule = namespace("App");

@Component({
  components: {PortalCheckbox, SingleFileHolder},
  computed: {
    REREmployeeStatus() {
      return REREmployeeStatus
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  }
})
export default class PublicEmployeeRER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: PublicREREmployeeDTO | null = null;

  addressIsDifferent = false

  mounted(): void {
    this.loadRequest();
  }

  loadRequest(): void {
    this.startLoading();
    RERService.getEmployeeByPublicId(this.id).then(
        ok => {
          this.request = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  saveWithoutStateIdBack(): void {
    this.request!.saveWithoutStateIdBack = true;
    this.save();
  }

  save(): void {
    if (!this.request) {
      return;
    }
    this.startLoading();
    RERService.saveEmployeeByPublicId(this.id, this.request).then(
        ok => {
          this.stopLoading();
          this.loadRequest();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get id(): string {
    return this.$route.params.id;
  }

  get isFilledMandatoryDocuments(): boolean {
    return this.isFilledFederalDocument
        && this.isFilledSSN
        && this.isFilledStateID
        && this.isFilledBankDetails;
  }

  get isFilledFederalDocument(): boolean {
    switch (this.request?.residencyStatus) {
      case ResidencyStatus.CITIZEN:
        return (!this.request.citizenPassportRequired || !!this.request?.citizenPassportImage);
      case ResidencyStatus.PERMANENT_RESIDENT:
        return (!this.request.greenCardRequired || !!(this.request?.greenCardFrontImage && this.request?.greenCardBackImage));
      case ResidencyStatus.NON_CITIZEN:
        return (!this.request.eadRequired || !!(this.request?.eadFrontImage && this.request?.eadBackImage))
      default:
        return false;
    }
  }

  get isFilledSSN(): boolean {
    return !this.request?.ssnRequired || !!this.request.ssnImage;
  }

  get isFilledStateID(): boolean {
    return !!this.request && (!this.request.stateIdRequired || (this.request.latestStateIdState && this.request?.stateIdIsValid) || !!this.request.stateIdFrontImage);
  }

  get isFilledBankDetails(): boolean {
    return !!this.request && (!this.request.bankDetailsRequired || (this.request.currentAccountBank && this.request.currentAccountIsValid) || !!this.request.voidCheckImage);
  }

}
